<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <router-link
          class="button-default button-back uni-back"
          tag="button"
          :to="{ name: 'battles' }"
          ><span></span>Назад</router-link
        >
        <div class="cabinet__head">
          <div class="cabinet__group">
            <div class="cabinet__heading">
              <h1 class="title">Результат последнего поединка</h1>
            </div>
          </div>
        </div>
        <div class="cabinet__body">
          <Loading
            v-if="loading"
            position="center"
            theme="dark"
            style="width: 100%"
          />
          <div v-else class="battle battle_page_history">
            <div v-if="battles.length" class="battle-main">
              <div class="battle-main-head">
                <div class="battle-main-head__item">
                  <div class="battle-main-head__label">Дата проведения:</div>
                  <div class="battle-main-head__title">
                    <template
                      v-if="
                        getMonth(new Date(battles[0].startDay)) ===
                        getMonth(addDays(new Date(battles[0].startDay), 13))
                      "
                    >
                      {{ battles[0].startDay | formatDateBegin }}-{{
                        battles[0].startDay | formatDateEnd
                      }}
                    </template>
                    <template v-else>
                      {{ battles[0].startDay | formatDateBeginMonth }}-{{
                        battles[0].startDay | formatDateEnd
                      }}
                    </template>
                  </div>
                </div>
                <div class="battle-main-head__item">
                  <div class="battle-main-head__label">KPI поединка:</div>
                  <div class="battle-main-head__title">
                    {{ battles[0].kpi }}
                  </div>
                </div>
              </div>
              <div class="battle-main-body">
                <div
                  class="battle-main-body__item"
                  :class="{
                    winner:
                      battles[0].playerMatchState === 1 ||
                      battles[0].playerMatchState === 3,
                    loser: battles[0].playerMatchState === 2,
                  }"
                >
                  <div class="battle-main-body__head">
                    <div
                      v-if="battles[0].playerPhoto"
                      class="battle-main-body__pic"
                    >
                      <span
                        :style="{
                          'background-image': `url(${
                            getBaseURL() + battles[0].playerPhoto
                          })`,
                        }"
                      ></span>
                    </div>
                    <div v-else class="battle-main-body__pic default">
                      <span></span>
                    </div>
                    <div class="battle-main-body__name">
                      {{ battles[0].playerName }}
                    </div>
                  </div>
                  <div class="battle-main-body__content">
                    <div class="battle-main-body__result">
                      <p>
                        <span>{{ battles[0].kpi }}:</span
                        ><strong
                          >{{
                            battles[0].playerKpiValue === null
                              ? "Отсутствовал"
                              : battles[0].playerKpiValue
                          }}
                          {{
                            battles[0].playerKpiValue === null
                              ? ""
                              : battles[0].measure
                          }}</strong
                        >
                      </p>
                      <p>
                        <span>Награда GPB-coins:</span
                        ><strong
                          ><b class="uni-coins">
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            /><template
                              v-if="battles[0].playerMatchState === 1"
                            >
                              {{ battles[0].winnerReward }}
                            </template>
                            <template
                              v-else-if="battles[0].playerMatchState === 2"
                            >
                              {{ battles[0].looserReward }}
                            </template>
                            <template
                              v-else-if="battles[0].playerMatchState === 3"
                            >
                              {{ battles[0].reward }}
                            </template>
                          </b></strong
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="battle-main-body__item"
                  :class="{
                    loser: battles[0].playerMatchState === 1,
                    winner:
                      battles[0].playerMatchState === 2 ||
                      battles[0].playerMatchState === 3,
                  }"
                >
                  <div class="battle-main-body__head">
                    <div
                      v-if="battles[0].fightVsShadow"
                      class="battle-main-body__pic kranos"
                    >
                      <span></span>
                    </div>
                    <div
                      v-else-if="battles[0].opponentPhoto"
                      class="battle-main-body__pic"
                    >
                      <span
                        :style="{
                          'background-image': `url(${
                            getBaseURL() + battles[0].opponentPhoto
                          })`,
                        }"
                      ></span>
                    </div>
                    <div v-else class="battle-main-body__pic default">
                      <span></span>
                    </div>
                    <div class="battle-main-body__name">
                      {{ battles[0].opponentName }}
                      <Tooltip
                        v-if="battles[0].fightVsShadow"
                        class="cabinet__heading-tooltip"
                        :msg="`Это поединок с Краносом. Кранос использует результат случайного дефендера. В случае победы Краноса - награда достается ему, а дефендер не узнает, что Кранос использует его результат в своих целях.`"
                      />
                    </div>
                  </div>
                  <div class="battle-main-body__content">
                    <div class="battle-main-body__result">
                      <p>
                        <span>{{ battles[0].kpi }}:</span
                        ><strong
                          >{{
                            battles[0].opponentKpiValue === null
                              ? "Отсутствовал"
                              : battles[0].opponentKpiValue
                          }}
                          {{
                            battles[0].playerKpiValue === null
                              ? ""
                              : battles[0].measure
                          }}</strong
                        >
                      </p>
                      <p>
                        <span>Награда GPB-coins:</span
                        ><strong
                          ><b class="uni-coins">
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            /><template
                              v-if="battles[0].playerMatchState === 2"
                            >
                              {{ battles[0].winnerReward }}
                            </template>
                            <template
                              v-else-if="battles[0].playerMatchState === 1"
                            >
                              {{ battles[0].looserReward }}
                            </template>
                            <template
                              v-else-if="battles[0].playerMatchState === 3"
                            >
                              {{ battles[0].reward }}
                            </template>
                          </b></strong
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="battle-message">
              Здесь ты увидишь последний загруженный результат своего поединка
              Чтобы принять участие в поединке, нажми “Участвовать” на этапе
              сбора заявок
            </div>
          </div>
        </div>
        <div v-if="!loading" class="cabinet__head">
          <div class="cabinet__group">
            <div class="cabinet__heading">
              <h1 class="title">История</h1>
            </div>
          </div>
        </div>
        <div v-if="!loading" class="cabinet__body">
          <div class="battle battle_page_history">
            <template v-if="battles.length > 1">
              <div
                v-for="(battle, index) in historyBattles"
                :key="index"
                class="battle-main"
              >
                <div class="battle-main-head">
                  <div class="battle-main-head__item">
                    <div class="battle-main-head__label">Дата проведения:</div>
                    <div class="battle-main-head__title">
                      <template
                        v-if="
                          getMonth(new Date(battle.startDay)) ===
                          getMonth(addDays(new Date(battle.startDay), 13))
                        "
                      >
                        {{ battle.startDay | formatDateBegin }}-{{
                          battle.startDay | formatDateEnd
                        }}
                      </template>
                      <template v-else>
                        {{ battle.startDay | formatDateBeginMonth }}-{{
                          battle.startDay | formatDateEnd
                        }}
                      </template>
                    </div>
                  </div>
                  <div class="battle-main-head__item">
                    <div class="battle-main-head__label">KPI поединка:</div>
                    <div class="battle-main-head__title">
                      {{ battle.kpi }}
                    </div>
                  </div>
                </div>
                <div class="battle-main-body">
                  <div
                    class="battle-main-body__item"
                    :class="{
                      winner:
                        battle.playerMatchState === 1 ||
                        battle.playerMatchState === 3,
                      loser: battle.playerMatchState === 2,
                    }"
                  >
                    <div class="battle-main-body__head">
                      <div
                        v-if="battle.playerPhoto"
                        class="battle-main-body__pic"
                      >
                        <span
                          :style="{
                            'background-image': `url(${
                              getBaseURL() + battle.playerPhoto
                            })`,
                          }"
                        ></span>
                      </div>
                      <div v-else class="battle-main-body__pic default">
                        <span></span>
                      </div>
                      <div class="battle-main-body__name">
                        {{ battle.playerName }}
                      </div>
                    </div>
                    <div class="battle-main-body__content">
                      <div class="battle-main-body__result">
                        <p>
                          <span>{{ battle.kpi }}:</span
                          ><strong
                            >{{
                              battle.playerKpiValue === null
                                ? "Отсутствовал"
                                : battle.playerKpiValue
                            }}
                            {{
                              battle.playerKpiValue === null
                                ? ""
                                : battle.measure
                            }}</strong
                          >
                        </p>
                        <p>
                          <span>Награда GPB-coins:</span
                          ><strong
                            ><b class="uni-coins">
                              <img
                                src="@/assets/img/pic2/coin.svg"
                                width="15"
                                height="15"
                                alt=""
                              />
                              <template v-if="battle.playerMatchState === 1">
                                {{ battle.winnerReward }}
                              </template>
                              <template
                                v-else-if="battle.playerMatchState === 2"
                              >
                                {{ battle.looserReward }}
                              </template>
                              <template
                                v-else-if="battle.playerMatchState === 3"
                              >
                                {{ battle.reward }}
                              </template>
                            </b></strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="battle-main-body__item"
                    :class="{
                      loser: battle.playerMatchState === 1,
                      winner:
                        battle.playerMatchState === 2 ||
                        battle.playerMatchState === 3,
                    }"
                  >
                    <div class="battle-main-body__head">
                      <div
                        v-if="battle.fightVsShadow"
                        class="battle-main-body__pic kranos"
                      >
                        <span></span>
                      </div>
                      <div
                        v-else-if="battle.opponentPhoto"
                        class="battle-main-body__pic"
                      >
                        <span
                          :style="{
                            'background-image': `url(${
                              getBaseURL() + battle.opponentPhoto
                            })`,
                          }"
                        ></span>
                      </div>
                      <div v-else class="battle-main-body__pic default">
                        <span></span>
                      </div>
                      <div class="battle-main-body__name">
                        {{ battle.opponentName }}
                        <Tooltip
                          v-if="battle.fightVsShadow"
                          class="cabinet__heading-tooltip"
                          :msg="`Это поединок с Краносом. Кранос использует результат случайного дефендера. В случае победы Краноса - награда достается ему, а дефендер не узнает, что Кранос использует его результат в своих целях.`"
                        />
                      </div>
                    </div>
                    <div class="battle-main-body__content">
                      <div class="battle-main-body__result">
                        <p>
                          <span>{{ battle.kpi }}:</span
                          ><strong
                            >{{
                              battle.opponentKpiValue === null
                                ? "Отсутствовал"
                                : battle.opponentKpiValue
                            }}
                            {{
                              battle.playerKpiValue === null
                                ? ""
                                : battle.measure
                            }}</strong
                          >
                        </p>
                        <p>
                          <span>Награда GPB-coins:</span
                          ><strong
                            ><b class="uni-coins">
                              <img
                                src="@/assets/img/pic2/coin.svg"
                                width="15"
                                height="15"
                                alt=""
                              /><template v-if="battle.playerMatchState === 2">
                                {{ battle.winnerReward }}
                              </template>
                              <template
                                v-else-if="battle.playerMatchState === 1"
                              >
                                {{ battle.looserReward }}
                              </template>
                              <template
                                v-else-if="battle.playerMatchState === 3"
                              >
                                {{ battle.reward }}
                              </template>
                            </b></strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="battle-message">
              Здесь ты увидишь историю всех своих поединков. История появится,
              после того, как ты примешь участие в двух поединках
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import Loading from "@/components/Loading.vue";
import { GetBattleHistory } from "@/api/Battles";
import Tooltip from "@/components/Tooltip.vue";
import { format, addDays, getMonth } from "date-fns";
import { ru } from "date-fns/locale";
import { getBaseURL } from "@/utils";

export default {
  components: {
    CommonLayout,
    Loading,
    Tooltip,
  },
  data() {
    return {
      loading: true,
      // playerMatchState
      // 0 - InProgress,
      // 1 - Won,
      // 2 - Loose,
      // 3 - Draw
      battles: [],
    };
  },
  filters: {
    formatDateBegin(date) {
      if (!date) return "";
      return format(new Date(date), "d", {
        locale: ru,
      });
    },
    formatDateBeginMonth(date) {
      if (!date) return "";
      return format(new Date(date), "d MMMM", {
        locale: ru,
      });
    },
    formatDateEnd(date) {
      if (!date) return "";
      return format(addDays(new Date(date), 13), "d MMMM yyyy", {
        locale: ru,
      });
    },
  },
  computed: {
    historyBattles() {
      return this.battles.slice(1);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    getBaseURL,
    addDays,
    getMonth,
    async fetchData() {
      this.loading = true;
      try {
        const resp = await GetBattleHistory();

        this.battles = resp.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" src="@/styles/battle.scss"></style>
